@import "../../../styles/variables"

.service-tab
    width: 100%
    display: flex
    flex-direction: column
    align-items: center

    .heading-fourth
        color: $orange
        text-align: right
        font-family: Lato
        font-size: clamp(12.5px,5.840999999999999vw,50px)
        font-style: normal
        font-weight: 500
        line-height: normal
        text-transform: capitalize

    .service-description
        color: $black
        font-family: Inter
        font-size: $font16mob
        font-style: normal
        font-weight: 400
        line-height: 146%
        text-transform: capitalize
        text-align: center

    .scroll-link
        width: clamp(130px,60.746399999999994vw,520px)
        height: $m64mob
        font-family: Inter
        font-size: $font22mob
        font-style: normal
        font-weight: 400
        line-height: normal
        display: flex
        align-items: center
        justify-content: center
        background-color: $black
        color: $orange
        border: none

@media screen and  (min-width: 1024px)

    .tabs
        width: 100%
        height: auto
        display: flex
        flex-direction: column

        .service-tab-pc
            width: 100%
            height: auto
            display: flex
            justify-content: space-between
            align-items: flex-start

            .service-content
                display: flex
                flex-direction: column

                .paragraph
                    font-size: clamp(9.5px,0.9895200000000001vw,38px)

                .scroll-link
                    width: clamp(130px,13.5408vw,520px)
                    height: clamp(26.5px,2.76024vw,106px)
                    color: $orange
                    font-family: Inter
                    font-size: clamp(11px,1.1457600000000001vw,44px)
                    font-style: normal
                    font-weight: 400
                    line-height: normal
                    display: flex
                    align-items: center
                    justify-content: center
                    background-color: $black
                    cursor: pointer
                    transition: 0.3s all
                    &:hover
                        background-color: $orange
                        color: $black

            .service-img
                width: clamp(212.5px,22.134vw,850px)
                height: clamp(212.5px,22.134vw,850px)
