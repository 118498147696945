@import "../../../styles/variables"

.swiper-slide
    display: flex
    align-items: center
    justify-content: center

.popup-content
    background-color: transparent !important
    background: transparent !important
    border: none !important

.form-container
    width: 90%
    margin: clamp(25px,11.681999999999999vw,100px) auto
    display: flex
    flex-direction: column
    align-items: center
    background-color: $black
    border-radius: 0px
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)

    .mob-form-step-1, .mob-form-step-2, .mob-form-step-3
        width: 100%
        display: flex
        flex-direction: column
        align-items: center

    .pc-form-step-1, .pc-form-step-2, .pc-form-step-3
        display: none

    .form-group
        width: 83%
        // margin-top: $m32mob
        // margin-bottom: clamp(10px,4.6728vw,40px)

        .form-swiper
            width: 100%

            .slide
                display: flex
                flex-direction: column

                .car-card
                    width: clamp(90px,42.0552vw,360px)
                    height: clamp(90px,42.0552vw,360px)
                    display: flex
                    flex-direction: column
                    align-items: center
                    background-color: $white
                    cursor: pointer

                    .checkbox
                        width: clamp(10px,4.6728vw,40px)
                        height: clamp(10px,4.6728vw,40px)
                        border: 2px solid #ddd
                        appearance: none
                        border-radius: 50%
                        margin-top: $m16mob
                        &:checked
                            background-color: $orange

                    .card-text
                        color: $orange
                        font-family: Inter
                        font-size: clamp(8.5px,3.9718799999999996vw,34px)
                        font-style: normal
                        font-weight: 400
                        line-height: normal
                        text-transform: capitalize
                        margin-top: $m16mob
                        // margin-bottom: $m8mob

                    .car-card-image
                        width: 90%
                        // margin-top: $m8mob

                .field
                    width: 65%
                    height: clamp(22.5px,10.5138vw,90px)
                    display: flex
                    align-items: center
                    justify-content: center
                    background-color: $white

                    .text, .icon
                        font-size: clamp(11px,5.140079999999999vw,44px)

                    .text
                        font-family: Inter
                        margin-left: $m16mob

    .form-heading
        color: $orange
        font-family: Inter
        font-size: $font18mob
        font-style: normal
        font-weight: 400
        line-height: normal
        text-transform: capitalize
        margin-top: $m16mob
        margin-bottom: $m16mob

    .label
        color: #FFF
        font-family: Inter
        font-size: $m16mob
        font-style: normal
        font-weight: 400 !important
        line-height: normal
        text-transform: capitalize
        display: block
        margin-bottom: 10px
        font-weight: bold

    .selector
        color: $black
        font-family: Inter
        font-size: $font14mob
        font-style: normal
        font-weight: 400
        line-height: normal
        text-transform: capitalize

    .b-form
        width: 100%
        height: clamp(20px,9.3456vw,80px)
        color: $black
        font-family: Inter
        font-size: clamp(7px,3.2709599999999996vw,28px)
        font-style: normal
        font-weight: 400
        line-height: normal
        text-transform: capitalize
        padding-left: $m8mob

    .counter
        width: 100%
        height: clamp(24px,11.21472vw,96px)
        background-color: $white
        display: flex
        justify-content: space-between
        align-items: center

        .counter-btn
            font-size: $m32mob
            color: $orange
            background: none
            border: none
            border-radius: 5px
            cursor: pointer
            background-color: transparent !important

        .left
            margin-left: $m16mob

        .right
            margin-right: $m16mob

        .quantity
            color: $black
            font-family: Inter
            font-size: $font18mob
            font-style: normal
            font-weight: 400
            line-height: normal
            text-transform: capitalize

    .submit-button
        width: 85%
        height: clamp(24px,11.21472vw,96px)
        background-color: $orange
        color: white
        border: none
        border-radius: 0px
        cursor: pointer
        margin-top: $m32mob
        margin-bottom: $m32mob

@media screen and  (min-width: 1024px)

    .form-container
        width: clamp(690px,71.8704vw,2760px)
        height: auto
        display: flex
        flex-direction: column
        align-items: center
        margin: auto
        margin-top: $m64
        margin-bottom: $m32

    .mob-form-step-1, .mob-form-step-2, .mob-form-step-3
        display: none !important

    .pc-form-step-1, .pc-form-step-2, .pc-form-step-3
        width: 90.57971014492753% !important
        display: flex !important
        flex-direction: column
        align-items: center

        .gallery-switcher
            width: 50%
            height: auto
            display: flex
            flex-direction: column
            align-items: center
            text-align: center
            margin: auto

            .field
                width: 50% !important
                height: clamp(18px,1.87488vw,72px) !important

                .text, .icon
                    margin-left: $m8 !important
                    font-size: clamp(9px,0.93744vw,36px) !important

            .form-group

                .label
                    color: $white
                    font-family: Inter
                    font-size: $font18
                    font-style: normal
                    font-weight: 400
                    line-height: normal
                    text-transform: capitalize

                .slide
                    display: flex
                    flex-direction: column

                    .car-card
                        width: clamp(125px,13.02vw,500px)
                        height: clamp(125px,13.02vw,500px)

                        .checkbox
                            width: clamp(10px,1.0416vw,40px) !important
                            height: clamp(10px,1.0416vw,40px) !important
                            border: 2px solid #ddd !important
                            appearance: none
                            border-radius: 50%
                            margin-top: $m16 !important
                            &:checked
                                background-color: $orange

                        .card-text
                            font-size: clamp(12.5px,1.302vw,50px)
                            margin-top: clamp(6px,0.62496vw,24px)

                        .card-text
                            font-size: clamp(8.5px,0.88536vw,34px) !important

        .title-row
            width: 70%
            height: auto
            display: flex
            align-items: center
            justify-content: space-between
            margin-top: $m32

            .title
                color: $white
                font-family: Inter
                font-size: clamp(9px,0.93744vw,36px)
                font-style: normal
                font-weight: 400
                line-height: normal
                text-transform: capitalize

        .input-row
            width: 100%
            height: auto
            display: flex
            align-items: center
            justify-content: space-between

            .form-group
                width: auto
                height: auto
                display: flex
                flex-direction: column
                align-items: flex-start

                .label
                    color: $white
                    font-family: Inter
                    font-size: $font18
                    font-style: normal
                    font-weight: 400
                    line-height: normal
                    text-transform: capitalize

                .selector, .b-form
                    width: clamp(125px,13.02vw,500px) !important
                    height: clamp(27.5px,2.8644000000000003vw,110px) !important
                    font-size: $m16
                    border-radius: 0px !important

                .counter
                    width: clamp(125px,13.02vw,500px)
                    height: clamp(19px,1.9790400000000001vw,76px)!important
                    border-radius: 3px
                    margin-bottom: $m16

                .counter-btn
                    font-size: $m32

                .right
                    padding-right: $m8

                .left
                    padding-left: $m8 !important

                .quantity
                    font-size: $font18

        .submit-button
            width: clamp(174px,18.12384vw,696px) !important
            height: clamp(25px,2.604vw,100px) !important
            color: $black !important
            font-family: Inter
            font-size: clamp(11px,1.1457600000000001vw,44px) !important
            font-style: normal
            font-weight: 400
            line-height: normal
            background: $orange
            transition: 0.3s all
            margin-top: clamp(24px,2.49984vw,96px) !important
            margin-bottom: clamp(24px,2.49984vw,96px)!important
            &:hover
                color: $white !important
