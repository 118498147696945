@import "./variables"

*
    margin: 0
    padding: 0
    box-sizing: border-box

#root
    width: 100%
    height: auto

.pointer
    cursor: pointer

.react-datepicker-wrapper
    width: 100% !important

.form-lower-mob
    display: block !important

.service
    width: clamp(172.5px,80.6058vw,690px) !important
    height: clamp(172.5px,80.6058vw,690px) !important

.react-time-picker
    width: 100%
    background-color: $white

.screen
    width: 100%
    height: auto
    display: flex
    flex-direction: column

.heading-container
    display: flex
    flex-direction: column

.align-start
    align-items: flex-start !important

.align-center
    align-items: center !important

.ml8
    margin-left: $m8mob

.ml16
    margin-left: $m16mob

.pc-form
    display: none

.main-heading
    color: $black
    font-family: Lato
    font-size: $font36mob
    font-style: normal
    font-weight: 800
    line-height: normal
    text-transform: capitalize

.heading-s
    color: $black
    font-family: Lato
    font-size: $font32mob
    font-style: normal
    font-weight: 800
    line-height: normal
    text-transform: capitalize

.heading-third
    color: $orange
    font-family: Lato
    font-size: clamp(14px,6.541919999999999vw,56px)
    font-style: normal
    font-weight: 700
    line-height: 146%

.heading-fourth
    color: $black
    text-align: right
    font-family: Lato
    font-size: clamp(13.5px,6.30828vw,54px)
    font-style: normal
    font-weight: 400
    line-height: normal
    text-transform: capitalize

.content
    width: 90%
    height: auto
    margin: auto
    display: flex
    flex-direction: column
    align-items: center
    margin: auto

.sub-heading
    color: $black
    font-family: Lato
    font-size: $font17mob
    font-style: normal
    font-weight: 400
    line-height: normal
    text-transform: capitalize

.text-center
    text-align: center

.justify-center
    justify-content: center !important

.text-right
    text-align: right
    float: right

.text-left
    text-align: left !important
    float: left !important

.paragraph
    color: $black
    font-family: Inter
    font-size: clamp(8.5px,3.9718799999999996vw,34px)
    font-style: normal
    font-weight: 400
    line-height: 144%

.small-text
    color: $black
    text-align: right
    font-family: Lato
    font-size: clamp(11px,5.140079999999999vw,44px)
    font-style: normal
    font-weight: 400
    line-height: normal
    text-transform: capitalize

.gray
    background: rgba(217, 217, 217, 0.20)

.right
    float: right

.white-bg
    background-color: $white !important

.white
    color: $white !important

.orange
    color: $orange !important

.orange-bg
    background-color: $orange

.white-bg
    background-color: $white !important

.mt8
    margin-top: $m8mob

.mt16
    margin-top: $m16mob

.mt32
    margin-top: $m32mob !important

.mt48
    margin-top: clamp(24px,11.21472vw,96px)

.mt64
    margin-top: $m64mob

.mb64
    margin-bottom: $m64mob

.mt128
    margin-top: $m128mob

.mb64
    margin-bottom: $m64mob

.items-end
    align-items: flex-end !important

.items-start
    align-items: start !important

.items-center
    align-items: center !important

.w100
    width: 100% !important

.w90
    width: 90%

.w80
    width: 80%

.w75
    width: 75%

.w70
    width: 70%

.w60
    width: 60%

.w50
    width: 50%

.w40
    width: 40%

.w30
    width: 30%

.content-pc
    display: none

.image
    display: inline-block

.auto
    margin: auto

// .car-img-gallery
//     width: clamp(200px,20.832vw,800px)
//     height: clamp(200px,20.832vw,800px)

@media screen and  (min-width: 1024px)

    .ml8
        margin-left: $m8

    .form-lower-mob
        display: none !important

    .car-img-gallery
        width: clamp(200px,20.832vw,800px)
        height: clamp(200px,20.832vw,800px)

    .service
        width: clamp(172.5px,17.9676vw,690px) !important
        height: clamp(172.5px,17.9676vw,690px) !important

    .mt356
        margin-top: clamp(178px,18.54048vw,712px) !important

    .pc-form
        display: flex

    .black
        color: $black

    .image
        display: none

    .mt8
        margin-top: $m8

    .mt16
        margin-top: $m16

    .mt32
        margin-top: $m32 !important

    .mt48
        margin-top: clamp(24px,11.21472vw,96px)

    .mt64
        margin-top: $m64

    .mb64
        margin-bottom: $m64

    .mb128
        margin-bottom: $m128

    .mt128
        margin-top: $m128 !important

    .mt256
        margin-top: clamp(128px,13.33248vw,512px) !important

    .mb64
        margin-bottom: $m64

    .content
        display: none

    .content-pc
        width: 86.45833333333333%
        height: auto
        margin: auto
        display: flex
        flex-direction: row
        justify-content: space-between
        margin: auto

        .text-content
            width: auto
            display: inline-flex
            flex-direction: column
            align-items: flex-start

        .paragraph
            color: $black
            font-family: Inter
            display: inline-block
            font-size: $font20
            font-style: normal
            font-weight: 400
            line-height: 144%

        .main-heading
            color: $black
            font-family: Lato
            display: inline-block
            font-size: clamp(35px,3.6456vw,140px)
            font-style: normal
            font-weight: 800
            line-height: normal
            text-transform: capitalize

        .sub-heading
            color: $black
            font-family: Lato
            font-size: clamp(10.5px,1.09368vw,42px)
            font-style: normal
            font-weight: 400
            line-height: normal
            text-transform: capitalize

        .button-row
            width: 61%
            display: inline-flex
            flex-direction: row
            align-items: flex-end
            justify-content: space-between

        .pc-img
            width: clamp(295px,30.7272vw,1180px)
            height: clamp(345px,35.9352vw,1380px)

    .jc-center
        justify-content: center !important

    .column
        flex-direction: column !important

    .heading-s
        color: $black
        font-family: Lato
        font-size: clamp(25px,2.604vw,100px)
        font-style: normal
        font-weight: 800
        line-height: normal
        text-transform: capitalize

    .feature-heading
        color: $black
        font-family: Lato
        font-size: clamp(22.5px,2.3436vw,90px)
        font-style: normal
        font-weight: 800
        line-height: normal
        text-transform: capitalize

    .heading-third
        color: $orange
        font-family: Lato
        font-size: clamp(14px,1.45824vw,56px)
        font-style: normal
        font-weight: 700
        line-height: 146%

    .heading-fourth
        color: $black
        text-align: right
        font-family: Lato
        font-size: clamp(13.5px,1.40616vw,54px)
        font-style: normal
        font-weight: 400
        line-height: normal
        text-transform: capitalize
