@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0px;
  background-color: #FF5722;
  padding: 10px;
  text-align: center;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}

.cookie-text {
  color: white;
  margin: 0 0 10px 0;
}

.accept-button {
  background-color: white;
  color: #FF5722;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.accept-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}/*# sourceMappingURL=cookies.css.map */