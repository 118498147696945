@import "../../../styles/variables"

.service-tab-s
    display: flex
    flex-direction: column
    align-items: flex-start

    .service-text
        color: $black
        text-align: right
        font-family: Lato
        font-size: $font22mob
        font-style: normal
        font-weight: 500
        line-height: normal
        text-transform: capitalize

    .more
        color: $orange
        text-align: right
        font-family: Inter
        font-size: $font16mob
        font-style: normal
        font-weight: 400
        line-height: normal
        text-decoration-line: underline
        text-transform: capitalize

@media screen and  (min-width: 1024px)

    .services-list
        width: 100%
        height: auto
        display: flex
        align-items: center
        justify-content: space-between

        .service-tab-s
            display: flex
            flex-direction: column
            align-items: flex-start


            .service
                width: clamp(178px,18.54048vw,712px)
                height: clamp(178px,18.54048vw,712px)
                cursor: pointer
                transition: 0.3s all
                &:hover
                    scale: 1.05

            .service-text
                color: $black
                text-align: right
                font-family: Lato
                font-size: clamp(11px,1.1457600000000001vw,44px)
                font-style: normal
                font-weight: 500
                line-height: normal
                text-transform: capitalize

            .more
                color: $orange
                text-align: right
                font-family: Inter
                font-size: $m16
                font-style: normal
                font-weight: 400
                line-height: normal
                text-decoration-line: underline
                text-transform: capitalize
                cursor: pointer
