@import "../../../styles/variables"

.request-form
    width: 90%
    margin: clamp(25px, 11.682vw, 100px) auto
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 0px

    .pc-form
        display: none

    .mobile-form
        width: 100%
        height: auto
        display: flex
        flex-direction: column
        align-items: center

        .input-group
            width: 100%
            display: flex
            flex-direction: column
            margin-bottom: $m32mob

            .comment
                width: 100%
                height: clamp(66.5px,31.074119999999997vw,266px)
                padding-top: $m16mob
                padding-left: $m16mob
                color: #969696
                font-family: Inter
                font-size: $m16mob
                font-style: normal
                font-weight: 400
                line-height: 137.4%

            .label-in
                color: $orange
                font-family: Inter
                font-size: $font16mob
                font-style: normal
                font-weight: 400
                line-height: 137.4%
                margin-bottom: $m8mob

            .counter
                width: 100%
                height: clamp(24px,11.21472vw,96px)
                background-color: $white
                display: flex
                justify-content: space-between
                border: 2px solid rgba(44, 44, 44, 0.50)
                align-items: center

                .minus, .plus
                    background: none
                    background-color: transparent
                    outline: none
                    border: none
                    font-size: $font32mob
                    color: $orange
                    cursor: pointer

                .quantity
                    color: $black
                    font-family: Inter
                    font-size: $font18mob
                    font-style: normal
                    font-weight: 400
                    line-height: normal
                    text-transform: capitalize

                .minus
                    margin-left: $m16mob

                .plus
                    margin-right: $m16mob

            .input-form
                width: 100%
                border: 2px solid rgba(44, 44, 44, 0.50)
                height: clamp(25px,11.681999999999999vw,100px)
                transition: 0.3s all
                outline: none
                padding-left: $m16
                &:focus
                    border: 2px solid $orange
                    outline: none

        .form-heading
            color: $orange
            font-family: Inter
            font-size: $font18mob
            font-style: normal
            font-weight: 400
            line-height: normal
            text-transform: capitalize
            margin-top: $m16mob
            margin-bottom: $m16mob

        .label
            color: #FFF
            font-family: Inter
            font-size: $m16mob
            font-style: normal
            font-weight: bold !important
            line-height: normal
            text-transform: capitalize
            display: block
            margin-bottom: 10px

        .selector
            color: $black
            font-family: Inter
            font-size: $font14mob
            font-style: normal
            font-weight: 400
            line-height: normal
            text-transform: capitalize

        .counter
            width: 100%
            height: clamp(24px, 11.21472vw, 96px)
            background-color: $white
            display: flex
            justify-content: space-between
            align-items: center

            &-btn
                font-size: $m32mob
                color: $orange
                background: none
                border: none
                border-radius: 5px
                cursor: pointer
                background-color: transparent !important

            .left
                margin-left: $m16mob

            .right
                margin-right: $m16mob

            .quantity
                color: $black
                font-family: Inter
                font-size: $font18mob
                font-style: normal
                font-weight: 400
                line-height: normal
                text-transform: capitalize

@media screen and  (min-width: 1024px)

    .request-form
        width: 36.875%
        height: auto
        margin: auto
        display: flex !important

        .mobile-form
            display: none

        .pc-form
            width: 100%
            display: flex
            flex-direction: column

        .input-row
            width: 100%
            height: auto
            display: flex
            align-items: center
            justify-content: space-between
            margin-top: $m64

            .input-group, .input-group-l
                width: auto
                display: flex
                flex-direction: column

                .label-in
                    color: $orange
                    font-family: Inter
                    font-size: clamp(8px,0.83328vw,32px)
                    font-style: normal
                    font-weight: 400
                    line-height: 137.4%

                .input-form, .input-form-adress
                    width: clamp(144.5px,15.051120000000001vw,578px)
                    height: clamp(25px,2.604vw,100px)
                    color: rgba(44, 44, 44, 0.60)
                    font-family: Inter
                    font-size: $m16
                    font-style: normal
                    font-weight: 400
                    line-height: 137.4%
                    border: 2px solid rgba(44, 44, 44, 0.50)
                    margin-top: $m16
                    padding-left: $m8
                    &:focus
                        outline: none
                        border: 2px solid $orange

                .input-form-adress
                    width: 100% !important

                .input-form-comment
                    width: 100%
                    height: clamp(66.5px,6.92664vw,266px)
                    color: #969696
                    font-family: Inter
                    font-size: $m16
                    font-style: normal
                    font-weight: 400
                    line-height: 137.4%
                    padding-top: $m8
                    padding-left: $m8
                    border: 2px solid rgba(44, 44, 44, 0.50)
                    margin-top: $m16
                    &:focus
                        outline: none
                        border: 2px solid $orange

                .counter
                    width: clamp(144.5px,15.051120000000001vw,578px)
                    height: clamp(25px,2.604vw,100px)
                    color: rgba(44, 44, 44, 0.60)
                    display: flex
                    align-items: center
                    justify-content: space-between
                    border: 2px solid rgba(44, 44, 44, 0.50)
                    margin-top: $m16

                    .minus, .plus
                        font-size: clamp(16px,1.66656vw,64px)
                        color: $orange
                        background-color: transparent
                        border: none
                        cursor: pointer

                    .minus
                        padding-left: $m16

                    .plus
                        padding-right: $m16

                    .quantity
                        color: $black
                        font-family: Inter
                        font-size: clamp(10.5px,1.09368vw,42px)
                        font-style: normal
                        font-weight: 400
                        line-height: normal
                        text-transform: capitalize

            .input-group-l
                width: 100%
