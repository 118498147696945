@import "../../../../styles/variables"

.img
    display: none

.service-img-mob
    display: flex

@media screen and  (min-width: 1024px)

    .service-img-mob
        display: none !important

    .heading-service
        color: $orange
        font-family: Lato
        font-size: clamp(22px,2.2915200000000002vw,88px)
        font-style: normal
        font-weight: 600
        line-height: normal
        text-transform: capitalize

    .form-heading
        color: $black
        text-align: right
        font-family: Lato
        font-size: clamp(17.5px,1.8228vw,70px)
        font-style: normal
        font-weight: 400
        line-height: normal
        text-transform: capitalize

    .pc-img-service
        position: absolute
        width: clamp(382px,39.789120000000004vw,1528px) !important
        height: clamp(250px,26.04vw,1000px) !important
        right: 0
