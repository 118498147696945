@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.rates-tab {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.rates-tab .rate-text {
  color: #FF5722;
  font-family: Lato;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  float: right;
  text-align: right;
  margin-bottom: clamp(4px, 1.86912vw, 16px);
}
.rates-tab .rate-name {
  color: #2C2C2C;
  font-family: Lato;
  font-size: clamp(11px, 5.14008vw, 44px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: clamp(4px, 1.86912vw, 16px);
}
.rates-tab .rate-price-hour, .rates-tab .rate-price-mile {
  color: #FF5722;
  font-family: Lato;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: clamp(8px, 3.73824vw, 32px);
}

@media screen and (min-width: 1024px) {
  .rates-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rates-list .rates-row {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .rates-list .rates-row .rate-text {
    color: #FF5722;
    font-family: Lato;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    float: right;
    text-align: right;
    margin-bottom: clamp(4px, 0.41664vw, 16px);
  }
  .rates-list .rates-row .rate-name {
    color: #2C2C2C;
    font-family: Lato;
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: clamp(4px, 0.41664vw, 16px);
  }
  .rates-list .rates-row .rate-price-hour, .rates-list .rates-row .rate-price-mile {
    color: #FF5722;
    font-family: Lato;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .rates-list .rates-row .rate-img {
    width: clamp(178px, 18.54048vw, 712px);
    height: clamp(178px, 18.54048vw, 712px);
    transition: 0.3s all;
    cursor: pointer;
  }
  .rates-list .rates-row .rate-img:hover {
    scale: 1.02;
  }
  .rates-list .rates-row-centered {
    width: 47.5%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .rates-list .rates-row-centered .rate-text {
    color: #FF5722;
    font-family: Lato;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    float: right;
    text-align: right;
    margin-bottom: clamp(4px, 0.41664vw, 16px);
  }
  .rates-list .rates-row-centered .rate-name {
    color: #2C2C2C;
    font-family: Lato;
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: clamp(4px, 0.41664vw, 16px);
  }
  .rates-list .rates-row-centered .rate-price-hour, .rates-list .rates-row-centered .rate-price-mile {
    color: #FF5722;
    font-family: Lato;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .rates-list .rates-row-centered .rate-img {
    width: clamp(178px, 18.54048vw, 712px);
    height: clamp(178px, 18.54048vw, 712px);
    transition: 0.3s all;
    cursor: pointer;
  }
  .rates-list .rates-row-centered .rate-img:hover {
    scale: 1.02;
  }
}/*# sourceMappingURL=car-tab-styles.css.map */