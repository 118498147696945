@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.bread-dots {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bread-dots .link {
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%;
  text-decoration: none;
}
.bread-dots .orange {
  color: #FF5722;
}
.bread-dots .circle {
  width: clamp(2.5px, 1.1682vw, 10px);
  height: clamp(2.5px, 1.1682vw, 10px);
  border-radius: 10px;
  background-color: #2C2C2C;
  margin-left: clamp(4px, 1.86912vw, 16px);
  margin-right: clamp(4px, 1.86912vw, 16px);
}
.bread-dots .circle-orange {
  width: clamp(2.5px, 1.1682vw, 10px);
  height: clamp(2.5px, 1.1682vw, 10px);
  border-radius: 10px;
  background-color: #FF5722;
  margin-left: clamp(4px, 1.86912vw, 16px);
  margin-right: clamp(4px, 1.86912vw, 16px);
}

.car-heading {
  width: 80%;
  color: #FF5722;
  font-family: Lato;
  font-size: clamp(16px, 7.47648vw, 64px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.car-heading-s {
  width: 80%;
  color: #FF5722;
  font-family: Lato;
  font-size: clamp(14px, 6.54192vw, 56px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.black {
  color: #2C2C2C;
}

.car-paragraph {
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: italic;
  font-weight: 400;
  line-height: 137.4%;
}

.button-row {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.price {
  color: #FF5722;
  font-family: Lato;
  font-size: clamp(8.5px, 3.97188vw, 34px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.request-table {
  width: 90%;
  height: auto;
  border: 3px solid #FF5722;
  margin-top: clamp(16px, 7.47648vw, 64px);
}

.car-swiper {
  width: 100%;
  height: auto;
  position: relative;
}

.photo {
  width: clamp(300px, 31.248vw, 1200px);
  height: clamp(300px, 31.248vw, 1200px);
  margin-left: clamp(64px, 6.66624vw, 256px);
}

.car-pc-img {
  display: none;
}

@media screen and (min-width: 1024px) {
  .car-swiper {
    display: none;
  }
  .bread-dots {
    display: flex;
    align-items: center;
  }
  .bread-dots .bread-link {
    color: #2C2C2C;
    font-family: Inter;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 400;
    line-height: 137.4%;
    text-decoration: none;
  }
  .bread-dots .bread-link .circle {
    width: clamp(2.5px, 0.2604vw, 10px);
    height: clamp(2.5px, 0.2604vw, 10px);
    background-color: #2C2C2C;
  }
  .bread-dots .bread-link .circle-orange {
    width: clamp(2.5px, 0.2604vw, 10px);
    height: clamp(2.5px, 0.2604vw, 10px);
    background-color: #FF5722;
  }
  .button-row-car {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  .button-row-car .prices-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .button-row-car .prices-group .text-price {
    color: #FF5722;
    font-family: Lato;
    font-size: clamp(10px, 1.0416vw, 40px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .car-pc-img {
    position: absolute;
    right: 0;
    display: flex;
    top: clamp(130px, 13.5408vw, 520px);
    width: clamp(450px, 46.872vw, 1800px);
  }
  .car-swiper-pc {
    width: 90%;
    height: auto;
  }
  .features-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}/*# sourceMappingURL=car.css.map */