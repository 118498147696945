@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: auto;
}

.pointer {
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.form-lower-mob {
  display: block !important;
}

.service {
  width: clamp(172.5px, 80.6058vw, 690px) !important;
  height: clamp(172.5px, 80.6058vw, 690px) !important;
}

.react-time-picker {
  width: 100%;
  background-color: white;
}

.screen {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.heading-container {
  display: flex;
  flex-direction: column;
}

.align-start {
  align-items: flex-start !important;
}

.align-center {
  align-items: center !important;
}

.ml8 {
  margin-left: clamp(4px, 1.86912vw, 16px);
}

.ml16 {
  margin-left: clamp(8px, 3.73824vw, 32px);
}

.pc-form {
  display: none;
}

.main-heading {
  color: #2C2C2C;
  font-family: Lato;
  font-size: clamp(18px, 8.41104vw, 72px);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;
}

.heading-s {
  color: #2C2C2C;
  font-family: Lato;
  font-size: clamp(16px, 7.47648vw, 64px);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;
}

.heading-third {
  color: #FF5722;
  font-family: Lato;
  font-size: clamp(14px, 6.54192vw, 56px);
  font-style: normal;
  font-weight: 700;
  line-height: 146%;
}

.heading-fourth {
  color: #2C2C2C;
  text-align: right;
  font-family: Lato;
  font-size: clamp(13.5px, 6.30828vw, 54px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.content {
  width: 90%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.sub-heading {
  color: #2C2C2C;
  font-family: Lato;
  font-size: clamp(8.5px, 3.97188vw, 34px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.justify-center {
  justify-content: center !important;
}

.text-right {
  text-align: right;
  float: right;
}

.text-left {
  text-align: left !important;
  float: left !important;
}

.paragraph {
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(8.5px, 3.97188vw, 34px);
  font-style: normal;
  font-weight: 400;
  line-height: 144%;
}

.small-text {
  color: #2C2C2C;
  text-align: right;
  font-family: Lato;
  font-size: clamp(11px, 5.14008vw, 44px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.gray {
  background: rgba(217, 217, 217, 0.2);
}

.right {
  float: right;
}

.white-bg {
  background-color: white !important;
}

.white {
  color: white !important;
}

.orange {
  color: #FF5722 !important;
}

.orange-bg {
  background-color: #FF5722;
}

.white-bg {
  background-color: white !important;
}

.mt8 {
  margin-top: clamp(4px, 1.86912vw, 16px);
}

.mt16 {
  margin-top: clamp(8px, 3.73824vw, 32px);
}

.mt32 {
  margin-top: clamp(16px, 7.47648vw, 64px) !important;
}

.mt48 {
  margin-top: clamp(24px, 11.21472vw, 96px);
}

.mt64 {
  margin-top: clamp(32px, 14.95296vw, 128px);
}

.mb64 {
  margin-bottom: clamp(32px, 14.95296vw, 128px);
}

.mt128 {
  margin-top: clamp(64px, 29.90592vw, 256px);
}

.mb64 {
  margin-bottom: clamp(32px, 14.95296vw, 128px);
}

.items-end {
  align-items: flex-end !important;
}

.items-start {
  align-items: start !important;
}

.items-center {
  align-items: center !important;
}

.w100 {
  width: 100% !important;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w75 {
  width: 75%;
}

.w70 {
  width: 70%;
}

.w60 {
  width: 60%;
}

.w50 {
  width: 50%;
}

.w40 {
  width: 40%;
}

.w30 {
  width: 30%;
}

.content-pc {
  display: none;
}

.image {
  display: inline-block;
}

.auto {
  margin: auto;
}

@media screen and (min-width: 1024px) {
  .ml8 {
    margin-left: clamp(4px, 0.41664vw, 16px);
  }
  .form-lower-mob {
    display: none !important;
  }
  .car-img-gallery {
    width: clamp(200px, 20.832vw, 800px);
    height: clamp(200px, 20.832vw, 800px);
  }
  .service {
    width: clamp(172.5px, 17.9676vw, 690px) !important;
    height: clamp(172.5px, 17.9676vw, 690px) !important;
  }
  .mt356 {
    margin-top: clamp(178px, 18.54048vw, 712px) !important;
  }
  .pc-form {
    display: flex;
  }
  .black {
    color: #2C2C2C;
  }
  .image {
    display: none;
  }
  .mt8 {
    margin-top: clamp(4px, 0.41664vw, 16px);
  }
  .mt16 {
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .mt32 {
    margin-top: clamp(16px, 1.66656vw, 64px) !important;
  }
  .mt48 {
    margin-top: clamp(24px, 11.21472vw, 96px);
  }
  .mt64 {
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  .mb64 {
    margin-bottom: clamp(32px, 3.33312vw, 128px);
  }
  .mb128 {
    margin-bottom: clamp(64px, 6.66624vw, 256px);
  }
  .mt128 {
    margin-top: clamp(64px, 6.66624vw, 256px) !important;
  }
  .mt256 {
    margin-top: clamp(128px, 13.33248vw, 512px) !important;
  }
  .mb64 {
    margin-bottom: clamp(32px, 3.33312vw, 128px);
  }
  .content {
    display: none;
  }
  .content-pc {
    width: 86.4583333333%;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
  }
  .content-pc .text-content {
    width: auto;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .content-pc .paragraph {
    color: #2C2C2C;
    font-family: Inter;
    display: inline-block;
    font-size: clamp(10px, 1.0416vw, 40px);
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
  }
  .content-pc .main-heading {
    color: #2C2C2C;
    font-family: Lato;
    display: inline-block;
    font-size: clamp(35px, 3.6456vw, 140px);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
  }
  .content-pc .sub-heading {
    color: #2C2C2C;
    font-family: Lato;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .content-pc .button-row {
    width: 61%;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  .content-pc .pc-img {
    width: clamp(295px, 30.7272vw, 1180px);
    height: clamp(345px, 35.9352vw, 1380px);
  }
  .jc-center {
    justify-content: center !important;
  }
  .column {
    flex-direction: column !important;
  }
  .heading-s {
    color: #2C2C2C;
    font-family: Lato;
    font-size: clamp(25px, 2.604vw, 100px);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
  }
  .feature-heading {
    color: #2C2C2C;
    font-family: Lato;
    font-size: clamp(22.5px, 2.3436vw, 90px);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
  }
  .heading-third {
    color: #FF5722;
    font-family: Lato;
    font-size: clamp(14px, 1.45824vw, 56px);
    font-style: normal;
    font-weight: 700;
    line-height: 146%;
  }
  .heading-fourth {
    color: #2C2C2C;
    text-align: right;
    font-family: Lato;
    font-size: clamp(13.5px, 1.40616vw, 54px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}/*# sourceMappingURL=global.css.map */