@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
}

.form-container {
  width: 90%;
  margin: clamp(25px, 11.682vw, 100px) auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2C2C2C;
  border-radius: 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.form-container .mob-form-step-1, .form-container .mob-form-step-2, .form-container .mob-form-step-3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-container .pc-form-step-1, .form-container .pc-form-step-2, .form-container .pc-form-step-3 {
  display: none;
}
.form-container .form-group {
  width: 83%;
}
.form-container .form-group .form-swiper {
  width: 100%;
}
.form-container .form-group .form-swiper .slide {
  display: flex;
  flex-direction: column;
}
.form-container .form-group .form-swiper .slide .car-card {
  width: clamp(90px, 42.0552vw, 360px);
  height: clamp(90px, 42.0552vw, 360px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  cursor: pointer;
}
.form-container .form-group .form-swiper .slide .car-card .checkbox {
  width: clamp(10px, 4.6728vw, 40px);
  height: clamp(10px, 4.6728vw, 40px);
  border: 2px solid #ddd;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 50%;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
.form-container .form-group .form-swiper .slide .car-card .checkbox:checked {
  background-color: #FF5722;
}
.form-container .form-group .form-swiper .slide .car-card .card-text {
  color: #FF5722;
  font-family: Inter;
  font-size: clamp(8.5px, 3.97188vw, 34px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
.form-container .form-group .form-swiper .slide .car-card .car-card-image {
  width: 90%;
}
.form-container .form-group .form-swiper .slide .field {
  width: 65%;
  height: clamp(22.5px, 10.5138vw, 90px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.form-container .form-group .form-swiper .slide .field .text, .form-container .form-group .form-swiper .slide .field .icon {
  font-size: clamp(11px, 5.14008vw, 44px);
}
.form-container .form-group .form-swiper .slide .field .text {
  font-family: Inter;
  margin-left: clamp(8px, 3.73824vw, 32px);
}
.form-container .form-heading {
  color: #FF5722;
  font-family: Inter;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: clamp(8px, 3.73824vw, 32px);
  margin-bottom: clamp(8px, 3.73824vw, 32px);
}
.form-container .label {
  color: #FFF;
  font-family: Inter;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: capitalize;
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.form-container .selector {
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(7px, 3.27096vw, 28px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.form-container .b-form {
  width: 100%;
  height: clamp(20px, 9.3456vw, 80px);
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(7px, 3.27096vw, 28px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-left: clamp(4px, 1.86912vw, 16px);
}
.form-container .counter {
  width: 100%;
  height: clamp(24px, 11.21472vw, 96px);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-container .counter .counter-btn {
  font-size: clamp(16px, 7.47648vw, 64px);
  color: #FF5722;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent !important;
}
.form-container .counter .left {
  margin-left: clamp(8px, 3.73824vw, 32px);
}
.form-container .counter .right {
  margin-right: clamp(8px, 3.73824vw, 32px);
}
.form-container .counter .quantity {
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.form-container .submit-button {
  width: 85%;
  height: clamp(24px, 11.21472vw, 96px);
  background-color: #FF5722;
  color: white;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  margin-top: clamp(16px, 7.47648vw, 64px);
  margin-bottom: clamp(16px, 7.47648vw, 64px);
}

@media screen and (min-width: 1024px) {
  .form-container {
    width: clamp(690px, 71.8704vw, 2760px);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: clamp(32px, 3.33312vw, 128px);
    margin-bottom: clamp(16px, 1.66656vw, 64px);
  }
  .mob-form-step-1, .mob-form-step-2, .mob-form-step-3 {
    display: none !important;
  }
  .pc-form-step-1, .pc-form-step-2, .pc-form-step-3 {
    width: 90.5797101449% !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .pc-form-step-1 .gallery-switcher, .pc-form-step-2 .gallery-switcher, .pc-form-step-3 .gallery-switcher {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: auto;
  }
  .pc-form-step-1 .gallery-switcher .field, .pc-form-step-2 .gallery-switcher .field, .pc-form-step-3 .gallery-switcher .field {
    width: 50% !important;
    height: clamp(18px, 1.87488vw, 72px) !important;
  }
  .pc-form-step-1 .gallery-switcher .field .text, .pc-form-step-1 .gallery-switcher .field .icon, .pc-form-step-2 .gallery-switcher .field .text, .pc-form-step-2 .gallery-switcher .field .icon, .pc-form-step-3 .gallery-switcher .field .text, .pc-form-step-3 .gallery-switcher .field .icon {
    margin-left: clamp(4px, 0.41664vw, 16px) !important;
    font-size: clamp(9px, 0.93744vw, 36px) !important;
  }
  .pc-form-step-1 .gallery-switcher .form-group .label, .pc-form-step-2 .gallery-switcher .form-group .label, .pc-form-step-3 .gallery-switcher .form-group .label {
    color: white;
    font-family: Inter;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .pc-form-step-1 .gallery-switcher .form-group .slide, .pc-form-step-2 .gallery-switcher .form-group .slide, .pc-form-step-3 .gallery-switcher .form-group .slide {
    display: flex;
    flex-direction: column;
  }
  .pc-form-step-1 .gallery-switcher .form-group .slide .car-card, .pc-form-step-2 .gallery-switcher .form-group .slide .car-card, .pc-form-step-3 .gallery-switcher .form-group .slide .car-card {
    width: clamp(125px, 13.02vw, 500px);
    height: clamp(125px, 13.02vw, 500px);
  }
  .pc-form-step-1 .gallery-switcher .form-group .slide .car-card .checkbox, .pc-form-step-2 .gallery-switcher .form-group .slide .car-card .checkbox, .pc-form-step-3 .gallery-switcher .form-group .slide .car-card .checkbox {
    width: clamp(10px, 1.0416vw, 40px) !important;
    height: clamp(10px, 1.0416vw, 40px) !important;
    border: 2px solid #ddd !important;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 50%;
    margin-top: clamp(8px, 0.83328vw, 32px) !important;
  }
  .pc-form-step-1 .gallery-switcher .form-group .slide .car-card .checkbox:checked, .pc-form-step-2 .gallery-switcher .form-group .slide .car-card .checkbox:checked, .pc-form-step-3 .gallery-switcher .form-group .slide .car-card .checkbox:checked {
    background-color: #FF5722;
  }
  .pc-form-step-1 .gallery-switcher .form-group .slide .car-card .card-text, .pc-form-step-2 .gallery-switcher .form-group .slide .car-card .card-text, .pc-form-step-3 .gallery-switcher .form-group .slide .car-card .card-text {
    font-size: clamp(12.5px, 1.302vw, 50px);
    margin-top: clamp(6px, 0.62496vw, 24px);
  }
  .pc-form-step-1 .gallery-switcher .form-group .slide .car-card .card-text, .pc-form-step-2 .gallery-switcher .form-group .slide .car-card .card-text, .pc-form-step-3 .gallery-switcher .form-group .slide .car-card .card-text {
    font-size: clamp(8.5px, 0.88536vw, 34px) !important;
  }
  .pc-form-step-1 .title-row, .pc-form-step-2 .title-row, .pc-form-step-3 .title-row {
    width: 70%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  .pc-form-step-1 .title-row .title, .pc-form-step-2 .title-row .title, .pc-form-step-3 .title-row .title {
    color: white;
    font-family: Inter;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .pc-form-step-1 .input-row, .pc-form-step-2 .input-row, .pc-form-step-3 .input-row {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pc-form-step-1 .input-row .form-group, .pc-form-step-2 .input-row .form-group, .pc-form-step-3 .input-row .form-group {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .pc-form-step-1 .input-row .form-group .label, .pc-form-step-2 .input-row .form-group .label, .pc-form-step-3 .input-row .form-group .label {
    color: white;
    font-family: Inter;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .pc-form-step-1 .input-row .form-group .selector, .pc-form-step-1 .input-row .form-group .b-form, .pc-form-step-2 .input-row .form-group .selector, .pc-form-step-2 .input-row .form-group .b-form, .pc-form-step-3 .input-row .form-group .selector, .pc-form-step-3 .input-row .form-group .b-form {
    width: clamp(125px, 13.02vw, 500px) !important;
    height: clamp(27.5px, 2.8644vw, 110px) !important;
    font-size: clamp(8px, 0.83328vw, 32px);
    border-radius: 0px !important;
  }
  .pc-form-step-1 .input-row .form-group .counter, .pc-form-step-2 .input-row .form-group .counter, .pc-form-step-3 .input-row .form-group .counter {
    width: clamp(125px, 13.02vw, 500px);
    height: clamp(19px, 1.97904vw, 76px) !important;
    border-radius: 3px;
    margin-bottom: clamp(8px, 0.83328vw, 32px);
  }
  .pc-form-step-1 .input-row .form-group .counter-btn, .pc-form-step-2 .input-row .form-group .counter-btn, .pc-form-step-3 .input-row .form-group .counter-btn {
    font-size: clamp(16px, 1.66656vw, 64px);
  }
  .pc-form-step-1 .input-row .form-group .right, .pc-form-step-2 .input-row .form-group .right, .pc-form-step-3 .input-row .form-group .right {
    padding-right: clamp(4px, 0.41664vw, 16px);
  }
  .pc-form-step-1 .input-row .form-group .left, .pc-form-step-2 .input-row .form-group .left, .pc-form-step-3 .input-row .form-group .left {
    padding-left: clamp(4px, 0.41664vw, 16px) !important;
  }
  .pc-form-step-1 .input-row .form-group .quantity, .pc-form-step-2 .input-row .form-group .quantity, .pc-form-step-3 .input-row .form-group .quantity {
    font-size: clamp(9px, 0.93744vw, 36px);
  }
  .pc-form-step-1 .submit-button, .pc-form-step-2 .submit-button, .pc-form-step-3 .submit-button {
    width: clamp(174px, 18.12384vw, 696px) !important;
    height: clamp(25px, 2.604vw, 100px) !important;
    color: #2C2C2C !important;
    font-family: Inter;
    font-size: clamp(11px, 1.14576vw, 44px) !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: #FF5722;
    transition: 0.3s all;
    margin-top: clamp(24px, 2.49984vw, 96px) !important;
    margin-bottom: clamp(24px, 2.49984vw, 96px) !important;
  }
  .pc-form-step-1 .submit-button:hover, .pc-form-step-2 .submit-button:hover, .pc-form-step-3 .submit-button:hover {
    color: white !important;
  }
}/*# sourceMappingURL=car-form.css.map */