@import "../../../styles/variables"

.charter-rates
    width: 100%
    border-collapse: collapse
    margin-top: $m32mob

    .table-heading
        width: 100%
        color: $black
        font-family: Lato
        font-size: $font18mob
        font-style: normal
        font-weight: 600
        line-height: normal
        text-transform: capitalize
        background: rgba(255, 87, 34, 0.10)

        .table-heading-text
            padding-top: $m16mob
            padding-left: $m16mob
            padding-bottom: $m16mob
            border: 3px solid $orange

    .rate-row
        width: 100%
        color: $black
        font-family: Lato
        font-size: $font16mob
        font-style: normal
        font-weight: 600
        line-height: normal
        text-transform: capitalize
        background: rgba(255, 87, 34, 0.10)

        .td
            padding-top: $m32mob
            padding-bottom: $m32mob
            text-align: center
            border: 3px solid $orange

    .rate-row-white
        width: 100%
        color: $black
        font-family: Lato
        font-size: $font16mob
        font-style: normal
        font-weight: 600
        line-height: normal
        text-transform: capitalize

        .td
            padding-top: $m32mob
            padding-bottom: $m32mob
            text-align: center
            border: 3px solid $orange

            .td-low
                padding-top: $m32mob
                text-align: center

@media screen and  (min-width: 1024px)

    .rates
        width: 100%
        height: auto
        display: flex
        flex-direction: column

        .rates-row
            width: 100%
            display: flex
            align-items: center
            justify-content: space-between

        .rates-row-centered
            width: 47.5%
            display: flex
            align-items: flex-end
            justify-content: space-between

    .charter-rates
        width: 70%
        border-collapse: collapse
        margin: auto
        margin-top: $m32

        .table-heading
            width: 100%
            color: $black
            font-family: Lato
            font-size: clamp(10.5px,1.09368vw,42px)
            font-style: normal
            font-weight: 600
            line-height: normal
            text-transform: capitalize
            text-align: left
            background: rgba(255, 87, 34, 0.10)

            .table-heading-text
                padding-top: $m16
                padding-left: $m16
                padding-bottom: $m16
                border: 3px solid $orange

        .rate-row
            width: 100%
            color: $black
            font-family: Lato
            font-size: $font18
            font-style: normal
            font-weight: 500
            line-height: normal
            text-align: left
            text-transform: capitalize
            background-color: $white

            .td
                padding-top: $m32
                padding-bottom: $m32
                padding-left: $m16
                text-align: left
                border: 3px solid $orange

        .rate-row-white
            width: 100%
            color: $black
            font-family: Lato
            font-size: $font16mob
            font-style: normal
            font-weight: 600
            line-height: normal
            text-transform: capitalize

            .td
                padding-top: $m32mob
                padding-bottom: $m32mob
                text-align: center
                border: 3px solid $orange

                .td-low
                    padding-top: $m32mob
                    text-align: center
