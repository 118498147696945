@import "../../../styles/variables"

.error-screen
    width: 100%
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .heading-main
        font-size: clamp(100px,10.416666666666666vw,400px)
