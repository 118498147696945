@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.review-tab {
  width: 70%;
  height: 250px;
  background-color: #F7F7F7;
  margin: auto;
  margin-bottom: clamp(8px, 3.73824vw, 32px);
}
.review-tab .review-upper {
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}
.review-tab .review-upper .review-name {
  width: 90%;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
.review-tab .review-upper .review-name .circle {
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 50px;
}
.review-tab .review-upper .review-name .client-name {
  color: #2C2C2C;
  text-align: right;
  font-family: Inter;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-left: clamp(4px, 1.86912vw, 16px);
}
.review-tab .review-upper .screenshot {
  width: clamp(100px, 46.728vw, 400px);
  height: clamp(14.5px, 6.77556vw, 58px);
  color: #2C2C2C;
  background-color: #FF5722;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 20px;
  border: none;
  margin-top: clamp(6px, 2.80368vw, 24px);
  cursor: pointer;
}
.review-tab .client-review {
  width: 90%;
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(7px, 3.27096vw, 28px);
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  text-transform: capitalize;
  margin: auto;
  margin-top: clamp(8px, 3.73824vw, 32px);
  margin-bottom: clamp(8px, 3.73824vw, 32px) !important;
}

.reviews-swiper {
  width: 100%;
  height: auto;
}
.reviews-swiper .swiper-button {
  background-color: #FF5722;
}

@media screen and (min-width: 1024px) {
  .review-tab {
    width: clamp(185.5px, 19.32168vw, 742px);
    height: clamp(169px, 17.60304vw, 676px);
    background-color: #F7F7F7;
    margin: auto;
    margin-bottom: clamp(8px, 3.73824vw, 32px);
  }
  .review-upper {
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .review-upper .review-name {
    width: 90%;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .review-upper .review-name .circle {
    width: clamp(24.5px, 2.55192vw, 98px) !important;
    height: clamp(24.5px, 2.55192vw, 98px) !important;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  .review-upper .review-name .client-name {
    color: #2C2C2C;
    text-align: right;
    font-family: Inter;
    font-size: clamp(11.5px, 1.19784vw, 46px) !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-left: clamp(4px, 0.41664vw, 16px);
  }
  .review-upper .screenshot {
    width: clamp(110px, 11.4576vw, 440px) !important;
    height: clamp(17.5px, 1.8228vw, 70px) !important;
    color: #2C2C2C;
    background-color: #FF5722;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 50px !important;
    border: none;
    margin-top: clamp(6px, 2.80368vw, 24px);
    cursor: pointer;
    transition: 0.3s all;
  }
  .review-upper .screenshot:hover {
    color: #FF5722;
    background-color: #2C2C2C;
  }
  .client-review {
    width: 90%;
    color: #2C2C2C;
    font-family: Inter;
    font-size: clamp(8px, 0.83328vw, 32px) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 146%;
    text-transform: capitalize;
    margin: auto;
    margin-top: clamp(8px, 0.83328vw, 32px) !important;
    margin-bottom: clamp(8px, 0.83328vw, 32px) !important;
  }
  .reviews-swiper {
    width: 100%;
    height: auto;
  }
  .reviews-swiper .swiper-button {
    background-color: #FF5722;
  }
}/*# sourceMappingURL=reviews-styles.css.map */