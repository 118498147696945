@import "../../../styles/variables"

.feature-tab
    display: flex
    flex-direction: column
    align-items: center

    .feature-heading
        color: #FFF
        font-family: Lato
        font-size: $font28mob
        font-style: normal
        font-weight: 800
        line-height: 146%
        text-transform: capitalize
        margin-top: $m32mob

    .feature-description
        color: $white
        font-family: Inter
        font-size: $m16mob
        font-style: normal
        font-weight: 400
        line-height: 146%
        text-transform: capitalize
        margin-top: $m8mob

@media screen and  (min-width: 1024px)

    .feature-tabs
        width: 60%
        display: flex
        align-items: center
        justify-content: space-between

    .feature-tab
        display: flex
        flex-direction: column
        align-items: center

        .feature-heading
            color: #FFF
            font-family: Lato
            font-size: clamp(22.5px,2.3436vw,90px)
            font-style: normal
            font-weight: 800
            line-height: 146%
            text-transform: capitalize
            margin-top: $m32mob

        .feature-description
            color: $white
            font-family: Inter
            font-size: clamp(9px,0.93744vw,36px)
            font-style: normal
            font-weight: 400
            line-height: 146%
            text-transform: capitalize
            margin-top: $m16

    .feature-img
        width: clamp(343.9px,35.820623999999995vw,1375.6px)
        height: clamp(288.5px,30.05016vw,1154px)
        margin-top: $m64
        margin-bottom: $m64
