@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.menu {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FF5722;
  z-index: 1000;
}
.menu .menu-content {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.menu .menu-content .hamburger-btn {
  position: absolute;
  right: clamp(12.5px, 5.841vw, 50px);
  top: clamp(16px, 7.47648vw, 64px);
}
.menu .menu-content .logo {
  width: clamp(100px, 46.728vw, 400px);
  height: clamp(50px, 23.364vw, 200px);
}
.menu .menu-content .separator {
  width: clamp(100px, 46.728vw, 400px);
  height: 1px;
  background-color: white;
}
.menu .menu-content .links {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu .menu-content .links .link {
  color: white;
  font-family: Inter;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}/*# sourceMappingURL=menu-styles.css.map */