@import "../../../../styles/variables"

.bread-dots
    display: flex
    align-items: center
    justify-content: flex-start

    .link
        color: $black
        font-family: Inter
        font-size: $font18mob
        font-style: normal
        font-weight: 400
        line-height: 137.4%
        text-decoration: none

    .orange
        color: $orange

    .circle
        width: clamp(2.5px,1.1682vw,10px)
        height: clamp(2.5px,1.1682vw,10px)
        border-radius: 10px
        background-color: $black
        margin-left: $m8mob
        margin-right: $m8mob

    .circle-orange
        width: clamp(2.5px,1.1682vw,10px)
        height: clamp(2.5px,1.1682vw,10px)
        border-radius: 10px
        background-color: $orange
        margin-left: $m8mob
        margin-right: $m8mob

.car-heading
    width: 80%
    color: $orange
    font-family: Lato
    font-size: clamp(16px,7.47648vw,64px)
    font-style: normal
    font-weight: 500
    line-height: normal
    text-transform: capitalize

.car-heading-s
    width: 80%
    color: $orange
    font-family: Lato
    font-size: clamp(14px,6.541919999999999vw,56px)
    font-style: normal
    font-weight: 600
    line-height: normal
    text-transform: capitalize

.black
    color: $black

.car-paragraph
    color: $black
    font-family: Inter
    font-size: $m16mob
    font-style: italic
    font-weight: 400
    line-height: 137.4%

.button-row
    display: flex
    align-items: flex-start
    flex-direction: column

.price
    color: $orange
    font-family: Lato
    font-size: $font17mob
    font-style: normal
    font-weight: 500
    line-height: normal
    text-transform: capitalize

.request-table
    width: 90%
    height: auto
    border: 3px solid $orange
    margin-top: $m32mob

.car-swiper
    width: 100%
    height: auto
    position: relative

.photo
    width: clamp(300px,31.248vw,1200px)
    height: clamp(300px,31.248vw,1200px)
    margin-left: $m128

.car-pc-img
    display: none

@media screen and  (min-width: 1024px)

    .car-swiper
        display: none

    .bread-dots
        display: flex
        align-items: center

        .bread-link
            color: $black
            font-family: Inter
            font-size: $font18
            font-style: normal
            font-weight: 400
            line-height: 137.4%
            text-decoration: none

            .circle
                width: clamp(2.5px,0.2604vw,10px)
                height: clamp(2.5px,0.2604vw,10px)
                background-color: $black

            .circle-orange
                width: clamp(2.5px,0.2604vw,10px)
                height: clamp(2.5px,0.2604vw,10px)
                background-color: $orange

    .button-row-car
        width: 30%
        display: flex
        flex-direction: row
        align-items: flex-end
        justify-content: space-between

        .prices-group
            display: flex
            flex-direction: column
            align-items: flex-start

            .text-price
                color: $orange
                font-family: Lato
                font-size: $font20
                font-style: normal
                font-weight: 500
                line-height: normal
                text-transform: capitalize

    .car-pc-img
        position: absolute
        right: 0
        display: flex
        top: clamp(130px,13.5408vw,520px)
        width: clamp(450px,46.872vw,1800px)

    .car-swiper-pc
        width: 90%
        height: auto

    .features-row
        width: 100%
        display: flex
        align-items: center
        justify-content: space-between
