@import "../../../styles/variables"

.rates-tab
    display: flex
    flex-direction: column
    cursor: pointer

    .rate-text
        color: $orange
        font-family: Lato
        font-size: $font16mob
        font-style: normal
        font-weight: 500
        line-height: normal
        text-transform: capitalize
        float: right
        text-align: right
        margin-bottom: $m8mob

    .rate-name
        color: $black
        font-family: Lato
        font-size: $font22mob
        font-style: normal
        font-weight: 500
        line-height: normal
        text-transform: capitalize
        margin-top: $m8mob

    .rate-price-hour, .rate-price-mile
        color: $orange
        font-family: Lato
        font-size: $font16mob
        font-style: normal
        font-weight: 500
        line-height: normal
        text-transform: capitalize
        margin-top: $m16mob

@media screen and  (min-width: 1024px)

    .rates-list
        width: 100%
        display: flex
        flex-direction: column
        align-items: center

        .rates-row
            width: 100%
            display: flex
            align-items: flex-end
            justify-content: space-between

            .rate-text
                color: $orange
                font-family: Lato
                font-size: $m16
                font-style: normal
                font-weight: 500
                line-height: normal
                text-transform: capitalize
                float: right
                text-align: right
                margin-bottom: $m8

            .rate-name
                color: $black
                font-family: Lato
                font-size: clamp(11px,1.1457600000000001vw,44px)
                font-style: normal
                font-weight: 500
                line-height: normal
                text-transform: capitalize
                margin-top: $m8

            .rate-price-hour, .rate-price-mile
                color: $orange
                font-family: Lato
                font-size: $m16
                font-style: normal
                font-weight: 500
                line-height: normal
                text-transform: capitalize
                margin-top: $m16

            .rate-img
                width: clamp(178px,18.54048vw,712px)
                height: clamp(178px,18.54048vw,712px)
                transition: 0.3s all
                cursor: pointer
                &:hover
                    scale: 1.02

        .rates-row-centered
            width: 47.5%
            display: flex
            align-items: flex-end
            justify-content: space-between

            .rate-text
                color: $orange
                font-family: Lato
                font-size: $m16
                font-style: normal
                font-weight: 500
                line-height: normal
                text-transform: capitalize
                float: right
                text-align: right
                margin-bottom: $m8

            .rate-name
                color: $black
                font-family: Lato
                font-size: clamp(11px,1.1457600000000001vw,44px)
                font-style: normal
                font-weight: 500
                line-height: normal
                text-transform: capitalize
                margin-top: $m8

            .rate-price-hour, .rate-price-mile
                color: $orange
                font-family: Lato
                font-size: $m16
                font-style: normal
                font-weight: 500
                line-height: normal
                text-transform: capitalize
                margin-top: $m16

            .rate-img
                width: clamp(178px,18.54048vw,712px)
                height: clamp(178px,18.54048vw,712px)
                transition: 0.3s all
                cursor: pointer
                &:hover
                    scale: 1.02
