@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.request-form {
  width: 90%;
  margin: clamp(25px, 11.682vw, 100px) auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
}
.request-form .pc-form {
  display: none;
}
.request-form .mobile-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.request-form .mobile-form .input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: clamp(16px, 7.47648vw, 64px);
}
.request-form .mobile-form .input-group .comment {
  width: 100%;
  height: clamp(66.5px, 31.07412vw, 266px);
  padding-top: clamp(8px, 3.73824vw, 32px);
  padding-left: clamp(8px, 3.73824vw, 32px);
  color: #969696;
  font-family: Inter;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%;
}
.request-form .mobile-form .input-group .label-in {
  color: #FF5722;
  font-family: Inter;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%;
  margin-bottom: clamp(4px, 1.86912vw, 16px);
}
.request-form .mobile-form .input-group .counter {
  width: 100%;
  height: clamp(24px, 11.21472vw, 96px);
  background-color: white;
  display: flex;
  justify-content: space-between;
  border: 2px solid rgba(44, 44, 44, 0.5);
  align-items: center;
}
.request-form .mobile-form .input-group .counter .minus, .request-form .mobile-form .input-group .counter .plus {
  background: none;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: clamp(16px, 7.47648vw, 64px);
  color: #FF5722;
  cursor: pointer;
}
.request-form .mobile-form .input-group .counter .quantity {
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.request-form .mobile-form .input-group .counter .minus {
  margin-left: clamp(8px, 3.73824vw, 32px);
}
.request-form .mobile-form .input-group .counter .plus {
  margin-right: clamp(8px, 3.73824vw, 32px);
}
.request-form .mobile-form .input-group .input-form {
  width: 100%;
  border: 2px solid rgba(44, 44, 44, 0.5);
  height: clamp(25px, 11.682vw, 100px);
  transition: 0.3s all;
  outline: none;
  padding-left: clamp(8px, 0.83328vw, 32px);
}
.request-form .mobile-form .input-group .input-form:focus {
  border: 2px solid #FF5722;
  outline: none;
}
.request-form .mobile-form .form-heading {
  color: #FF5722;
  font-family: Inter;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: clamp(8px, 3.73824vw, 32px);
  margin-bottom: clamp(8px, 3.73824vw, 32px);
}
.request-form .mobile-form .label {
  color: #FFF;
  font-family: Inter;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: bold !important;
  line-height: normal;
  text-transform: capitalize;
  display: block;
  margin-bottom: 10px;
}
.request-form .mobile-form .selector {
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(7px, 3.27096vw, 28px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.request-form .mobile-form .counter {
  width: 100%;
  height: clamp(24px, 11.21472vw, 96px);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.request-form .mobile-form .counter-btn {
  font-size: clamp(16px, 7.47648vw, 64px);
  color: #FF5722;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent !important;
}
.request-form .mobile-form .counter .left {
  margin-left: clamp(8px, 3.73824vw, 32px);
}
.request-form .mobile-form .counter .right {
  margin-right: clamp(8px, 3.73824vw, 32px);
}
.request-form .mobile-form .counter .quantity {
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

@media screen and (min-width: 1024px) {
  .request-form {
    width: 36.875%;
    height: auto;
    margin: auto;
    display: flex !important;
  }
  .request-form .mobile-form {
    display: none;
  }
  .request-form .pc-form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .request-form .input-row {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  .request-form .input-row .input-group, .request-form .input-row .input-group-l {
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .request-form .input-row .input-group .label-in, .request-form .input-row .input-group-l .label-in {
    color: #FF5722;
    font-family: Inter;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 400;
    line-height: 137.4%;
  }
  .request-form .input-row .input-group .input-form, .request-form .input-row .input-group .input-form-adress, .request-form .input-row .input-group-l .input-form, .request-form .input-row .input-group-l .input-form-adress {
    width: clamp(144.5px, 15.05112vw, 578px);
    height: clamp(25px, 2.604vw, 100px);
    color: rgba(44, 44, 44, 0.6);
    font-family: Inter;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 400;
    line-height: 137.4%;
    border: 2px solid rgba(44, 44, 44, 0.5);
    margin-top: clamp(8px, 0.83328vw, 32px);
    padding-left: clamp(4px, 0.41664vw, 16px);
  }
  .request-form .input-row .input-group .input-form:focus, .request-form .input-row .input-group .input-form-adress:focus, .request-form .input-row .input-group-l .input-form:focus, .request-form .input-row .input-group-l .input-form-adress:focus {
    outline: none;
    border: 2px solid #FF5722;
  }
  .request-form .input-row .input-group .input-form-adress, .request-form .input-row .input-group-l .input-form-adress {
    width: 100% !important;
  }
  .request-form .input-row .input-group .input-form-comment, .request-form .input-row .input-group-l .input-form-comment {
    width: 100%;
    height: clamp(66.5px, 6.92664vw, 266px);
    color: #969696;
    font-family: Inter;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 400;
    line-height: 137.4%;
    padding-top: clamp(4px, 0.41664vw, 16px);
    padding-left: clamp(4px, 0.41664vw, 16px);
    border: 2px solid rgba(44, 44, 44, 0.5);
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .request-form .input-row .input-group .input-form-comment:focus, .request-form .input-row .input-group-l .input-form-comment:focus {
    outline: none;
    border: 2px solid #FF5722;
  }
  .request-form .input-row .input-group .counter, .request-form .input-row .input-group-l .counter {
    width: clamp(144.5px, 15.05112vw, 578px);
    height: clamp(25px, 2.604vw, 100px);
    color: rgba(44, 44, 44, 0.6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid rgba(44, 44, 44, 0.5);
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .request-form .input-row .input-group .counter .minus, .request-form .input-row .input-group .counter .plus, .request-form .input-row .input-group-l .counter .minus, .request-form .input-row .input-group-l .counter .plus {
    font-size: clamp(16px, 1.66656vw, 64px);
    color: #FF5722;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .request-form .input-row .input-group .counter .minus, .request-form .input-row .input-group-l .counter .minus {
    padding-left: clamp(8px, 0.83328vw, 32px);
  }
  .request-form .input-row .input-group .counter .plus, .request-form .input-row .input-group-l .counter .plus {
    padding-right: clamp(8px, 0.83328vw, 32px);
  }
  .request-form .input-row .input-group .counter .quantity, .request-form .input-row .input-group-l .counter .quantity {
    color: #2C2C2C;
    font-family: Inter;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .request-form .input-row .input-group-l {
    width: 100%;
  }
}/*# sourceMappingURL=form.css.map */