@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.img {
  display: none;
}

.service-img-mob {
  display: flex;
}

@media screen and (min-width: 1024px) {
  .service-img-mob {
    display: none !important;
  }
  .heading-service {
    color: #FF5722;
    font-family: Lato;
    font-size: clamp(22px, 2.29152vw, 88px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .form-heading {
    color: #2C2C2C;
    text-align: right;
    font-family: Lato;
    font-size: clamp(17.5px, 1.8228vw, 70px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .pc-img-service {
    position: absolute;
    width: clamp(382px, 39.78912vw, 1528px) !important;
    height: clamp(250px, 26.04vw, 1000px) !important;
    right: 0;
  }
}/*# sourceMappingURL=airport-styles.css.map */