@import "../../../styles/variables"

.service-tab
    width: auto
    height: auto
    display: flex
    flex-direction: column
    align-items: center

    .service-square
        width: clamp(50px,23.363999999999997vw,200px)
        height: clamp(50px,23.363999999999997vw,200px)
        display: flex
        align-items: center
        justify-content: center
        background-color: $orange
        border-radius: 7px

        .icon
            width: $m64mob
            height: $m64mob

    .service-heading
        color: $black
        text-align: center
        font-family: Lato
        font-size: clamp(12.5px,5.840999999999999vw,50px)
        font-style: normal
        font-weight: 800
        line-height: normal
        text-transform: capitalize

    .service-paragraph
        color: $black
        text-align: center
        font-family: Inter
        font-size: $font18mob
        font-style: normal
        font-weight: 400
        line-height: 146.023%

@media screen and  (min-width: 1024px)

    .tabs-container
        width: 100%
        height: auto
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

        .service-tab
            width: auto
            height: auto
            display: flex
            flex-direction: column
            align-items: center

            .icon
                width: clamp(42.5px,4.4268vw,170px) !important
                height: clamp(42.5px,4.4268vw,170px) !important

            .service-square
                width: clamp(95px,9.8952vw,380px)
                height: clamp(95px,9.8952vw,380px)
                display: flex
                align-items: center
                justify-content: center
                background-color: $orange
                border-radius: 7px

                .icon
                    width: $m64
                    height: $m64

            .service-heading
                color: $black
                text-align: center
                font-family: Lato
                font-size: clamp(17.5px,1.8228vw,70px)
                font-style: normal
                font-weight: 800
                line-height: normal
                text-transform: capitalize

            .service-paragraph
                color: $black
                text-align: center
                font-family: Inter
                font-size: clamp(9px,0.93744vw,36px)
                font-style: normal
                font-weight: 400
                line-height: 146.023%
