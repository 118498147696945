@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.btn-container {
  width: auto;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
}

.btn {
  width: clamp(120px, 56.0736vw, 480px) !important;
  height: clamp(21.5px, 10.04652vw, 86px) !important;
  color: #2C2C2C !important;
  font-family: Inter !important;
  background-color: #FF5722;
  font-size: clamp(8.5px, 3.97188vw, 34px) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: clamp(8.5px, 3.97188vw, 34px) !important;
  cursor: pointer !important;
  z-index: 0 !important;
  overflow: hidden !important;
  border: none !important;
  border-radius: 0 !important;
  text-transform: none !important;
}

.reg-btn {
  width: clamp(120px, 56.0736vw, 480px) !important;
  height: clamp(24px, 11.21472vw, 96px) !important;
  color: #FF5722 !important;
  font-family: Inter !important;
  font-size: clamp(8.5px, 3.97188vw, 34px) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: clamp(8.5px, 3.97188vw, 34px) !important;
  background-color: #2C2C2C !important;
  cursor: pointer !important;
  z-index: 0 !important;
  overflow: hidden !important;
  border: none !important;
  border-radius: 0 !important;
  text-transform: none !important;
  margin: auto;
  margin-top: clamp(8px, 3.73824vw, 32px);
}

.pseudo {
  width: clamp(120px, 56.0736vw, 480px);
  height: clamp(21.5px, 10.04652vw, 86px);
  border: 2px solid #FF5722;
  z-index: -1;
  left: clamp(4.5px, 2.10276vw, 18px);
  top: -9px;
  position: absolute;
}

.pseudo-white {
  width: clamp(120px, 56.0736vw, 480px);
  height: clamp(21.5px, 10.04652vw, 86px);
  border: 2px solid white;
  z-index: -1;
  left: clamp(4.5px, 2.10276vw, 18px);
  top: -9px;
  position: absolute;
}

@media screen and (min-width: 1024px) {
  .btn-container {
    width: auto;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
  }
  .pseudo {
    width: clamp(136px, 14.16576vw, 544px) !important;
    height: clamp(25.5px, 2.65608vw, 102px) !important;
    border: 2px solid #FF5722;
    z-index: -1;
    left: clamp(4.5px, 0.46872vw, 18px);
    top: -9px;
    position: absolute;
  }
  .btn {
    width: clamp(136px, 14.16576vw, 544px) !important;
    height: clamp(25.5px, 2.65608vw, 102px) !important;
    color: #2C2C2C !important;
    font-family: Inter !important;
    font-size: clamp(10.5px, 1.09368vw, 42px) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    background-color: #FF5722;
    line-height: normal !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    z-index: 0 !important;
    overflow: hidden !important;
    border: none !important;
    border-radius: 0 !important;
    text-transform: none !important;
    transition: 0.3s all;
  }
  .btn:hover {
    color: #FF5722 !important;
    background-color: #2C2C2C !important;
  }
  .btn:hover .pseudo {
    background-color: red;
    border: 2px solid #2C2C2C !important;
  }
  .reg-btn {
    width: clamp(136px, 14.16576vw, 544px) !important;
    height: clamp(27.5px, 2.8644vw, 110px) !important;
    color: #FF5722 !important;
    font-family: Inter !important;
    font-size: clamp(10.5px, 1.09368vw, 42px) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #2C2C2C !important;
    cursor: pointer !important;
    z-index: 0 !important;
    overflow: hidden !important;
    border: none !important;
    border-radius: 0 !important;
    text-transform: none !important;
    margin: auto;
    transition: 0.3s all;
  }
  .reg-btn:hover {
    background-color: #FF5722 !important;
    color: #2C2C2C !important;
  }
  .pseudo-white {
    width: clamp(136px, 14.16576vw, 544px) !important;
    height: clamp(25.5px, 2.65608vw, 102px) !important;
    border: 2px solid white;
    z-index: -1;
    left: clamp(4.5px, 0.46872vw, 18px);
    top: -9px;
    position: absolute;
  }
}/*# sourceMappingURL=button-styles.css.map */