@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.service-tab-s {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.service-tab-s .service-text {
  color: #2C2C2C;
  text-align: right;
  font-family: Lato;
  font-size: clamp(11px, 5.14008vw, 44px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.service-tab-s .more {
  color: #FF5722;
  text-align: right;
  font-family: Inter;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: capitalize;
}

@media screen and (min-width: 1024px) {
  .services-list {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .services-list .service-tab-s {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .services-list .service-tab-s .service {
    width: clamp(178px, 18.54048vw, 712px);
    height: clamp(178px, 18.54048vw, 712px);
    cursor: pointer;
    transition: 0.3s all;
  }
  .services-list .service-tab-s .service:hover {
    scale: 1.05;
  }
  .services-list .service-tab-s .service-text {
    color: #2C2C2C;
    text-align: right;
    font-family: Lato;
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .services-list .service-tab-s .more {
    color: #FF5722;
    text-align: right;
    font-family: Inter;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: capitalize;
    cursor: pointer;
  }
}/*# sourceMappingURL=services-styles.css.map */