@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.service-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-tab .heading-fourth {
  color: #FF5722;
  text-align: right;
  font-family: Lato;
  font-size: clamp(12.5px, 5.841vw, 50px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.service-tab .service-description {
  color: #2C2C2C;
  font-family: Inter;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  text-transform: capitalize;
  text-align: center;
}
.service-tab .scroll-link {
  width: clamp(130px, 60.7464vw, 520px);
  height: clamp(32px, 14.95296vw, 128px);
  font-family: Inter;
  font-size: clamp(11px, 5.14008vw, 44px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2C2C2C;
  color: #FF5722;
  border: none;
}

@media screen and (min-width: 1024px) {
  .tabs {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .tabs .service-tab-pc {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .tabs .service-tab-pc .service-content {
    display: flex;
    flex-direction: column;
  }
  .tabs .service-tab-pc .service-content .paragraph {
    font-size: clamp(9.5px, 0.98952vw, 38px);
  }
  .tabs .service-tab-pc .service-content .scroll-link {
    width: clamp(130px, 13.5408vw, 520px);
    height: clamp(26.5px, 2.76024vw, 106px);
    color: #FF5722;
    font-family: Inter;
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2C2C2C;
    cursor: pointer;
    transition: 0.3s all;
  }
  .tabs .service-tab-pc .service-content .scroll-link:hover {
    background-color: #FF5722;
    color: #2C2C2C;
  }
  .tabs .service-tab-pc .service-img {
    width: clamp(212.5px, 22.134vw, 850px);
    height: clamp(212.5px, 22.134vw, 850px);
  }
}/*# sourceMappingURL=services.css.map */