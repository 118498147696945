@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.contact-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
.contact-form .input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
}
.contact-form .input-group .label {
  color: #FF5722;
  font-family: Inter;
  font-size: clamp(7.5px, 3.5046vw, 30px);
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%;
  margin-bottom: clamp(4px, 1.86912vw, 16px);
}
.contact-form .input-group .input {
  width: 100%;
  border: 2px solid rgba(44, 44, 44, 0.5);
  height: clamp(25px, 11.682vw, 100px);
  transition: 0.3s all;
  outline: none;
  padding-left: clamp(8px, 0.83328vw, 32px);
}
.contact-form .input-group .input:focus {
  border: 2px solid #FF5722;
  outline: none;
}

.map {
  width: 100%;
  height: auto;
  margin-top: clamp(16px, 7.47648vw, 64px);
}

.contact-link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-link-container .fa-icon {
  font-size: clamp(11px, 5.14008vw, 44px);
  color: #FF5722;
}
.contact-link-container .contact-link {
  color: #FF5722;
  text-align: right;
  font-family: Inter;
  font-size: clamp(10px, 4.6728vw, 40px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: clamp(4px, 1.86912vw, 16px);
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .contacts-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .contacts-container .contact-form {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px !important;
  }
  .contacts-container .contact-form .contact-heading {
    color: #2C2C2C;
    text-align: right;
    font-family: Lato;
    font-size: clamp(17.5px, 1.8228vw, 70px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .contacts-container .contact-form .input-group-pc {
    width: auto;
    display: flex;
    flex-direction: column;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  .contacts-container .contact-form .input-group-pc .label {
    color: #FF5722;
    font-family: Inter;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 400;
    line-height: 137.4%;
  }
  .contacts-container .contact-form .input-group-pc .form-input {
    width: clamp(270px, 28.1232vw, 1080px);
    height: clamp(21.5px, 2.23944vw, 86px);
    color: rgba(44, 44, 44, 0.6);
    font-family: Inter;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 400;
    line-height: 137.4%;
    border: 2px solid rgba(44, 44, 44, 0.5);
    padding-left: clamp(8px, 0.83328vw, 32px);
    outline: none;
    transition: 0.3s all;
  }
  .contacts-container .contact-form .input-group-pc .form-input:focus {
    outline: none;
    border: 2px solid #FF5722;
  }
  .contacts-container .contact-form .input-group-pc .form-input-comment {
    width: clamp(270px, 28.1232vw, 1080px);
    height: clamp(66.5px, 6.92664vw, 266px);
    color: rgba(44, 44, 44, 0.6);
    font-family: Inter;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 400;
    line-height: 137.4%;
    border: 2px solid rgba(44, 44, 44, 0.5);
    padding-left: clamp(8px, 0.83328vw, 32px);
    padding-top: clamp(8px, 0.83328vw, 32px);
    outline: none;
    transition: 0.3s all;
  }
  .contacts-container .contact-form .input-group-pc .form-input-comment:focus {
    outline: none;
    border: 2px solid #FF5722;
  }
  .contacts-container .map-content {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .contacts-container .map-content .location-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contacts-container .map-content .location-container .fa-icon, .contacts-container .map-content .location-container .contact-link {
    color: #FF5722;
    font-family: Inter;
    font-size: clamp(10px, 1.0416vw, 40px);
    font-style: normal;
    font-weight: 400;
    text-align: left;
    line-height: normal;
    margin-left: clamp(4px, 0.41664vw, 16px);
  }
}/*# sourceMappingURL=contacts.css.map */