@import "../../../styles/variables"

.car-feature
    width: auto
    height: auto
    display: flex
    flex-direction: column
    align-items: center
    border: 3px solid $orange

    .feature-content
        width: 90%
        height: auto
        display: flex
        flex-direction: column
        align-items: flex-start
        margin-top: $m32mob
        margin-bottom: $m32mob

        .feature-icon
            width: clamp(40.5px,18.92484vw,162px)
            height: clamp(40.5px,18.92484vw,162px)

        .feature-heading
            color: #2C2C2C
            font-family: Lato
            font-size: clamp(12px,5.60736vw,48px)
            font-style: normal
            font-weight: 700
            line-height: normal
            text-transform: capitalize
            margin-top: $m16mob

        .feature-description
            color: $black
            font-family: Inter
            font-size: $font16mob
            font-style: normal
            font-weight: 400
            line-height: 146%
            text-transform: capitalize
            margin-top: $m16mob

@media screen and  (min-width: 1024px)

    .car-feature
        width: clamp(190px,19.791666666666668vw,760px)
        height: clamp(265px,27.604166666666668vw,1060px) !important
        height: auto
        display: flex
        flex-direction: column
        align-items: center
        border: 3px solid $orange

        .feature-content
            width: 90%
            height: auto
            display: flex
            flex-direction: column
            align-items: flex-start
            margin-top: $m32
            margin-bottom: $m32

            .feature-icon
                width: clamp(40.5px,4.2184800000000005vw,162px)
                height: clamp(40.5px,4.2184800000000005vw,162px)

            .feature-heading
                color: #2C2C2C
                font-family: Lato
                font-size: clamp(13px,1.35408vw,52px)
                font-style: normal
                font-weight: 700
                line-height: normal
                text-transform: capitalize
                margin-top: $m16

            .feature-description
                width: 80%
                color: $black
                font-family: Inter
                font-size: $m16
                font-style: normal
                font-weight: 400
                line-height: 146%
                text-transform: capitalize
                margin-top: $m16
