@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.feature-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feature-tab .feature-heading {
  color: #FFF;
  font-family: Lato;
  font-size: clamp(14px, 6.54192vw, 56px);
  font-style: normal;
  font-weight: 800;
  line-height: 146%;
  text-transform: capitalize;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
.feature-tab .feature-description {
  color: white;
  font-family: Inter;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  text-transform: capitalize;
  margin-top: clamp(4px, 1.86912vw, 16px);
}

@media screen and (min-width: 1024px) {
  .feature-tabs {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .feature-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .feature-tab .feature-heading {
    color: #FFF;
    font-family: Lato;
    font-size: clamp(22.5px, 2.3436vw, 90px);
    font-style: normal;
    font-weight: 800;
    line-height: 146%;
    text-transform: capitalize;
    margin-top: clamp(16px, 7.47648vw, 64px);
  }
  .feature-tab .feature-description {
    color: white;
    font-family: Inter;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 400;
    line-height: 146%;
    text-transform: capitalize;
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .feature-img {
    width: clamp(343.9px, 35.820624vw, 1375.6px);
    height: clamp(288.5px, 30.05016vw, 1154px);
    margin-top: clamp(32px, 3.33312vw, 128px);
    margin-bottom: clamp(32px, 3.33312vw, 128px);
  }
}/*# sourceMappingURL=features-styles.css.map */