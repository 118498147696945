@import "../../../styles/variables"

.header
    width: 90%
    height: auto
    display: flex
    justify-content: space-between
    margin: auto
    margin-top: clamp(24px,11.21472vw,96px)

    .header-mob
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-between

        .logo-container
            width: 65%
            height: auto
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between

            .phone
                color: $orange
                font-size: clamp(12px,5.60736vw,48px)

    .header-pc
        display: none

@media screen and  (min-width: 1024px)

    .header
        width: 86.45833333333333%
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

        .header-mob
            display: none !important

        .header-pc
            width: 100%
            display: flex !important
            justify-content: space-between
            align-items: center

            .logo
                width: clamp(76.5px,7.96824vw,306px)
                height: clamp(30px,3.1248vw,120px)
                cursor: pointer

            .links
                display: flex
                flex-direction: row
                align-items: center

                .link
                    color: $black
                    font-family: Inter
                    font-size: clamp(10.5px,1.09368vw,42px)
                    font-style: normal
                    font-weight: 400
                    line-height: normal
                    cursor: pointer
                    transition: 0.3s all
                    margin-left: $m32
                    text-decoration: none
                    &:hover
                        color: $orange
