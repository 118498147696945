@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.request-table {
  width: 100%;
  border: 3px solid #FF5722;
  border-collapse: collapse;
}
.request-table .request-header {
  color: #2C2C2C;
  font-family: Lato;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  background: rgba(255, 87, 34, 0.1);
  padding-top: clamp(8px, 3.73824vw, 32px);
  padding-bottom: clamp(8px, 3.73824vw, 32px);
}
.request-table .request-data {
  color: #2C2C2C;
  font-family: Lato;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.request-table .request-row-main {
  width: 100%;
  display: table-row;
  text-align: center;
  border: 3px solid #FF5722;
  background: rgba(255, 87, 34, 0.1);
}
.request-table .request-row-main .request-data {
  width: 100%;
  border: none;
  padding-top: clamp(16px, 7.47648vw, 64px);
  padding-bottom: clamp(16px, 7.47648vw, 64px) !important;
}
.request-table .request-row-add {
  width: 100%;
  display: table-row;
  text-align: center;
  border: 3px solid #FF5722;
}
.request-table .request-row-add .request-data {
  width: 100%;
  border: none;
  padding-top: clamp(16px, 7.47648vw, 64px);
  padding-bottom: clamp(16px, 7.47648vw, 64px) !important;
}

.request-table-pc {
  display: none !important;
}

@media screen and (min-width: 1024px) {
  .request-tbody {
    display: none;
  }
  .request-table {
    width: 100% !important;
  }
  .request-table-pc {
    width: 100%;
    display: table !important;
    border: clamp(1.5px, 0.15624vw, 6px) solid #FF5722;
    border-collapse: collapse;
  }
  .request-table-pc .request-header-pc {
    color: #2C2C2C;
    font-family: Lato;
    font-size: clamp(12px, 1.24992vw, 48px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    background: rgba(255, 87, 34, 0.1);
    padding-top: clamp(8px, 0.83328vw, 32px);
    padding-bottom: clamp(8px, 0.83328vw, 32px);
  }
  .request-table-pc .table-additional-heading-pc {
    color: #2C2C2C;
    font-family: Lato;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    padding-top: clamp(16px, 1.66656vw, 64px);
    padding-bottom: clamp(16px, 1.66656vw, 64px);
    background: rgba(255, 87, 34, 0.1);
    border: clamp(1.5px, 0.15624vw, 6px) solid #FF5722;
  }
  .request-table-pc .table-additional-row-pc {
    color: #2C2C2C;
    font-family: Inter;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    padding-top: clamp(16px, 1.66656vw, 64px);
    padding-bottom: clamp(16px, 1.66656vw, 64px);
    border: clamp(1.5px, 0.15624vw, 6px) solid #FF5722;
  }
}/*# sourceMappingURL=price-table.css.map */