@import "../../../styles/variables"

.contact-form
    width: 100%
    height: auto
    display: flex
    flex-direction: column
    margin-top: $m64mob

    .input-group
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-start
        margin: auto

        .label
            color: $orange
            font-family: Inter
            font-size: clamp(7.5px,3.5046vw,30px)
            font-style: normal
            font-weight: 400
            line-height: 137.4%
            margin-bottom: $m8mob

        .input
            width: 100%
            border: 2px solid rgba(44, 44, 44, 0.50)
            height: clamp(25px,11.681999999999999vw,100px)
            transition: 0.3s all
            outline: none
            padding-left: $m16
            &:focus
                border: 2px solid $orange
                outline: none

.map
    width: 100%
    height: auto
    margin-top: $m32mob

.contact-link-container
    width: auto
    display: flex
    flex-direction: row
    align-items: center

    .fa-icon
        font-size: $font22mob
        color: $orange

    .contact-link
        width: auto
        color: $orange
        display: flex
        flex-direction: row
        text-align: right
        font-family: Inter
        font-size: $font20mob
        font-style: normal
        font-weight: 400
        line-height: normal
        margin-left: $m8mob
        text-decoration: none

    // .contact-link-adress
    //     color: $orange
    //     text-align: right
    //     font-family: Inter
    //     font-size: 14px
    //     font-style: normal
    //     font-weight: 400
    //     line-height: normal
    //     margin-left: $m8mob
    //     text-decoration: none

@media screen and  (min-width: 1024px)

    .contacts-container
        width: 100%
        height: auto
        display: flex
        justify-content: space-between
        align-items: flex-start

        .contact-form
            width: auto
            display: flex
            flex-direction: column
            align-items: center
            margin-top: 0px !important

            .contact-heading
                color: #2C2C2C
                text-align: right
                font-family: Lato
                font-size: clamp(17.5px,1.8228vw,70px)
                font-style: normal
                font-weight: 400
                line-height: normal
                text-transform: capitalize

            .input-group-pc
                width: auto
                display: flex
                flex-direction: column
                margin-top: $m32

                .label
                    color: $orange
                    font-family: Inter
                    font-size: clamp(8px,0.83328vw,32px)
                    font-style: normal
                    font-weight: 400
                    line-height: 137.4%

                .form-input
                    width: clamp(270px,28.1232vw,1080px)
                    height: clamp(21.5px,2.23944vw,86px)
                    color: rgba(44, 44, 44, 0.60)
                    font-family: Inter
                    font-size: $m16
                    font-style: normal
                    font-weight: 400
                    line-height: 137.4%
                    border: 2px solid rgba(44, 44, 44, 0.50)
                    padding-left: $m16
                    outline: none
                    transition: 0.3s all
                    &:focus
                        outline: none
                        border: 2px solid $orange

                .form-input-comment
                    width: clamp(270px,28.1232vw,1080px)
                    height: clamp(66.5px,6.92664vw,266px)
                    color: rgba(44, 44, 44, 0.60)
                    font-family: Inter
                    font-size: $m16
                    font-style: normal
                    font-weight: 400
                    line-height: 137.4%
                    border: 2px solid rgba(44, 44, 44, 0.50)
                    padding-left: $m16
                    padding-top: $m16
                    outline: none
                    transition: 0.3s all
                    &:focus
                        outline: none
                        border: 2px solid $orange

        .map-content
            width: auto
            height: auto
            display: flex
            flex-direction: column
            align-items: center
            margin-top: $m16

            .location-container
                display: flex
                flex-direction: column
                align-items: center

                .fa-icon, .contact-link
                    width: auto
                    color: $orange
                    font-family: Inter
                    font-size: clamp(10px,1.0416vw,40px)
                    font-style: normal
                    font-weight: 400
                    text-align: left
                    line-height: normal
                    margin-left: $m8
